import { Outlet, ShouldRevalidateFunction } from '@remix-run/react';

export const shouldRevalidate: ShouldRevalidateFunction = () => {
  // this loader should not reload since we only need to load it once
  // after visit, subsequence page navigations should not re-run this
  return false;
};

export default function WPShell() {
  return (
    <>
      <Outlet />
    </>
  );
}
