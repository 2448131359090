import { jsx, jsxs, Fragment } from "react/jsx-runtime";

// add resourceQuery so we won't recursively import
import { Links as RemixLinks } from "@remix-run/react?__bail";
export * from "@remix-run/react?__bail";

export const Links = /* #__PURE__ */ (props) => {
  return jsxs(Fragment, {
    children: [
      jsx(RemixLinks, props),
      ["https://web-bundles.setel.com/prod/web-common/latest/static/js/26851cc82317458ec465.chunk.js","https://web-bundles.setel.com/prod/web-common/latest/remoteEntry.js"].map((url, i) => jsx("link", {rel: "preload", href: url, as: "script"}, i)),
    ]
  })
}
